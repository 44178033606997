import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { authSetIdentityAndBootup } from '@app/auth/core/state/auth.actions';
import { concatMap, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { StorageService } from '@app/core/services/storage/storage.service';
import {
  cachingActivityValue,
  cachingExpandValue,
  cachingOrderListLimit,
  cachingTaskLimit,
  clearCachedFilters,
  getCachedActivityValue,
  getCachedConverterColumns,
  getCachedExpandValue,
  getCachedLinePanels,
  getCachedOrderListColumns,
  getCachedOrderListLimit,
  getCachedOrderPanels,
  getCachedOrdersFilters,
  getCachedOrdersSort,
  getCachedShipmentColumns,
  getCachedShipmentQuery,
  getCachedTaskFilters,
  getCachedTaskLimit,
  getCachedViewMode,
  initCachedConverterValues,
  initCachedExpandValues,
  saveToStorage,
  startInitCached,
  updateLinePanels,
  updateOrderPanels,
  validateCachedDestinations,
  validatedCachedDestinations,
} from '@app/cache/state/cache.actions';
import { CachedActivityType, CachedExpandType, CacheState } from '@app/cache/state/cache.reducer';
import { ROUTER_NAVIGATION, RouterNavigationPayload } from '@ngrx/router-store';
import { Router } from '@angular/router';
import {
  OrderFilterParameters,
  OrderQueryFilters,
  OrderQuerySort,
  OrderQueryViewMode,
  OrderSortParameters,
} from '@app/order/models';
import { select, Store } from '@ngrx/store';
import {
  orderListReset,
  orderListUpdateFilters,
  orderListUpdateLineColumns,
  orderListUpdateOrderColumns,
  orderListUpdateSort,
  orderListUpdateViewMode,
} from '@app/order/routes/order-list/state/order-list.actions';
import {
  selectCachedOrderListFilters,
  selectCachedOrderListLimit,
  selectCachedOrdersSort,
  selectCachedOrdersViewMode,
  selectCachedShipmentFiltersQuery,
  selectCachedTaskPageFilters,
  selectCachedTaskPageLimit,
} from '@app/cache/state/cache.selectors';
import { taskListResetFilters, taskListUpdateFilters } from '@app/task/routes/task-list/state/task-list.actions';
import { AssignedTo, TaskQueryFilters, TaskStatus } from '@app/task/models';
import { CachedKey, CachedTrackedVersionKey, CachedURL } from '@app/cache/models';
import { selectAuthIdentityCompanyId, selectAuthUserId } from '@app/auth/core/state/auth.selectors';
import { OrderedColumn } from '@app/shared/components/table-columns/table-columns.component';
import { companyFetch } from '@app/company/core/state/company.actions';
import { userFetch } from '@app/user/core/state/user.actions';
import { OrderSearchService } from '@app/order/core/services/order-search.service';
import {
  converterUpdateLineBuyerColumns,
  converterUpdateLineConfirmedColumns,
  converterUpdateLineGeneralColumns,
  converterUpdateLineSupplierColumns,
  converterUpdateOrderBuyerColumns,
  converterUpdateOrderGeneralColumns,
  converterUpdateOrderSupplierColumns,
} from '@app/converter/core/state/converter.actions';
import { ConvertedColumn } from '@app/converter/models';
import { CacheManagerService } from '@app/cache/services/cache-manager.service';
import { ShipmentQueryFilters } from '@app/shipment/models';
import { DestinationResponse } from '@app/order/models/order-destinations.model';
import { OrderPanelType } from '@app/order/private/components/order-panels/order-panels.component';
import { LinePanelType } from '@app/order/routes/order-line-detail/containers/view/line-panels/line-panels.component';
import { shipmentUpdateColumns } from '@app/shipment/core/state/shipment.actions';

@Injectable()
export class CacheEffects {
  validateVersion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authSetIdentityAndBootup),
      map(({ userId }) => {
        this.managerService.clearOutdatedKeys(userId);

        return startInitCached({ userId });
      }),
    );
  });

  init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(startInitCached),
      mergeMap(({ userId }) => {
        const orderDetailPage = this.managerService.getSafetyValueFromStorage<string>(
          `${userId}-${CachedKey.ACTIVITY}-${CachedActivityType.OrderPage}`,
        );
        const orderLineDetailPage = this.managerService.getSafetyValueFromStorage<string>(
          `${userId}-${CachedKey.ACTIVITY}-${CachedActivityType.OrderLinePage}`,
        );
        const dashboardPage = this.managerService.getSafetyValueFromStorage<string>(
          `${userId}-${CachedKey.ACTIVITY}-${CachedActivityType.DashboardPage}`,
        );
        const userPage = this.managerService.getSafetyValueFromStorage<string>(
          `${userId}-${CachedKey.ACTIVITY}-${CachedActivityType.UserSettingsPage}`,
        );
        const userNetworkPage = this.managerService.getSafetyValueFromStorage<string>(
          `${userId}-${CachedKey.ACTIVITY}-${CachedActivityType.UserNetworkPage}`,
        );
        const shipmentPage = this.managerService.getSafetyValueFromStorage<string>(
          `${userId}-${CachedKey.ACTIVITY}-${CachedActivityType.ShipmentPage}`,
        );

        const ordersPageViewMode = this.managerService.getSafetyValueFromStorage<OrderQueryViewMode>(
          `${userId}-${CachedKey.VIEW_MODE}`,
        );
        const ordersPageSort = this.managerService.getSafetyValueFromStorage<OrderQuerySort>(
          `${userId}-${CachedKey.SORT}`,
        );
        const ordersPageFilters = this.managerService.getSafetyValueFromStorage<OrderQueryFilters>(
          `${userId}-${CachedTrackedVersionKey.ORDERS_FILTERS}`,
        );

        const companyIds = (ordersPageFilters && ordersPageFilters.companyIds) || [];
        const contactIds = (ordersPageFilters && ordersPageFilters.contactIds) || [];
        const destinations = (ordersPageFilters && ordersPageFilters.destinations) || [];

        const validateCompanies = companyIds.map(id => {
          return companyFetch({ id, flush: false });
        });
        const validateOrderListUsers = contactIds.map(id => {
          return userFetch({ id });
        });

        const shipmentFilters = this.managerService.getSafetyValueFromStorage<ShipmentQueryFilters>(
          `${userId}-${CachedKey.SHIPMENT_FILTERS}`,
        );
        const shipmentColumns = this.managerService.getSafetyValueFromStorage<OrderedColumn[]>(
          `${userId}-${CachedTrackedVersionKey.SHIPMENT_COLUMNS}`,
        );

        const taskPageFilters = this.managerService.getSafetyValueFromStorage<TaskQueryFilters>(
          `${userId}-${CachedTrackedVersionKey.TASK_FILTERS}`,
        );

        const relatedCompanyIds = (taskPageFilters && taskPageFilters.relatedCompanyIds) || [];
        const assigneeUserIds = (taskPageFilters && taskPageFilters.contactUserIds) || [];

        const validateTaskCompanies = relatedCompanyIds.map(id => {
          return companyFetch({ id, flush: false });
        });
        const validateTaskUsers = assigneeUserIds.map(id => {
          return userFetch({ id });
        });

        const orderColumns = this.managerService.getSafetyValueFromStorage<OrderedColumn[]>(
          `${userId}-${CachedTrackedVersionKey.ORDER_COLUMNS}`,
        );
        const lineColumns = this.managerService.getSafetyValueFromStorage<OrderedColumn[]>(
          `${userId}-${CachedTrackedVersionKey.LINE_COLUMNS}`,
        );

        const ordersLimit = this.managerService.getSafetyValueFromStorage<number>(
          `${userId}-${CachedKey.ORDER_LIST_LIMIT}`,
        );
        const taskLimit = this.managerService.getSafetyValueFromStorage<number>(
          `${userId}-${CachedKey.WORKFLOW_LIMIT}`,
        );

        const orderPanels = this.managerService.getSafetyValueFromStorage<Record<OrderPanelType, number>>(
          `${userId}-${CachedTrackedVersionKey.ORDER_PANELS}`,
        );
        const linePanels = this.managerService.getSafetyValueFromStorage<Record<LinePanelType, number>>(
          `${userId}-${CachedTrackedVersionKey.LINE_PANELS}`,
        );

        return [
          initCachedExpandValues({ userId }),
          initCachedConverterValues({ userId }),

          getCachedActivityValue({ path: CachedActivityType.OrderPage, value: orderDetailPage }),
          getCachedActivityValue({ path: CachedActivityType.OrderLinePage, value: orderLineDetailPage }),
          getCachedActivityValue({ path: CachedActivityType.DashboardPage, value: dashboardPage }),
          getCachedActivityValue({ path: CachedActivityType.UserSettingsPage, value: userPage }),
          getCachedActivityValue({ path: CachedActivityType.UserNetworkPage, value: userNetworkPage }),
          getCachedActivityValue({ path: CachedActivityType.ShipmentPage, value: shipmentPage }),

          getCachedViewMode({ path: CachedActivityType.UserNetworkPage, viewMode: ordersPageViewMode }),
          getCachedOrdersSort({ path: CachedActivityType.UserNetworkPage, sort: ordersPageSort }),
          getCachedOrdersFilters({ filters: ordersPageFilters }),

          getCachedTaskFilters({
            filters: {
              assignedTo: AssignedTo.COMPANY,
              ...taskPageFilters,
            },
          }),
          getCachedShipmentQuery({ filters: shipmentFilters }),
          getCachedOrderListColumns({ orderColumns, lineColumns }),
          getCachedShipmentColumns({ columns: shipmentColumns }),

          getCachedOrderListLimit({ limit: Number(ordersLimit), offset: 0 }),
          getCachedTaskLimit({ limit: Number(taskLimit), offset: 0 }),

          validateCachedDestinations({ destinations }),

          getCachedOrderPanels({ value: orderPanels }),
          getCachedLinePanels({ value: linePanels }),

          ...validateCompanies,
          ...validateTaskCompanies,

          ...validateOrderListUsers,
          ...validateTaskUsers,
        ];
      }),
    );
  });

  initExpand$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(initCachedExpandValues),
      mergeMap(({ userId }) => {
        const expandActions = Object.values(CachedExpandType).map(value => {
          const isExpand = this.managerService.getSafetyValueFromStorage<boolean>(
            `${userId}-${CachedKey.EXPAND_COLLAPSE}-${value}`,
          );

          return getCachedExpandValue({ path: value, value: isExpand });
        });

        const panelsTypeList = [...Object.values(OrderPanelType), ...Object.values(LinePanelType)];

        const panelActions = panelsTypeList.map(value => {
          const isPanelExpand = this.managerService.getSafetyValueFromStorage<boolean>(
            `${userId}-${CachedKey.EXPAND_COLLAPSE}-${value}`,
          );

          return getCachedExpandValue({ path: value, value: isPanelExpand });
        });

        return [...panelActions, ...expandActions];
      }),
    );
  });

  initConverterCache$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(initCachedConverterValues),
      map(({ userId }) => {
        const generalOrderColumns =
          this.managerService.getSafetyValueFromStorage<ConvertedColumn[]>(
            `${userId}-${CachedTrackedVersionKey.CONVERTER_ORDER_GENERAL}`,
          ) || [];
        const buyerOrderColumns =
          this.managerService.getSafetyValueFromStorage<ConvertedColumn[]>(
            `${userId}-${CachedTrackedVersionKey.CONVERTER_ORDER_BUYER}`,
          ) || [];
        const supplierOrderColumns =
          this.managerService.getSafetyValueFromStorage<ConvertedColumn[]>(
            `${userId}-${CachedTrackedVersionKey.CONVERTER_ORDER_SUPPLIER}`,
          ) || [];

        const generalLineColumns =
          this.managerService.getSafetyValueFromStorage<ConvertedColumn[]>(
            `${userId}-${CachedTrackedVersionKey.CONVERTER_ORDER_LINE_GENERAL}`,
          ) || [];
        const buyerLineColumns =
          this.managerService.getSafetyValueFromStorage<ConvertedColumn[]>(
            `${userId}-${CachedTrackedVersionKey.CONVERTER_ORDER_LINE_BUYER}`,
          ) || [];
        const confirmedLineColumns =
          this.managerService.getSafetyValueFromStorage<ConvertedColumn[]>(
            `${userId}-${CachedTrackedVersionKey.CONVERTER_ORDER_LINE_CONFIRMED}`,
          ) || [];
        const supplierLineColumns =
          this.managerService.getSafetyValueFromStorage<ConvertedColumn[]>(
            `${userId}-${CachedTrackedVersionKey.CONVERTER_ORDER_LINE_SUPPLIER}`,
          ) || [];

        return getCachedConverterColumns({
          generalOrderColumns,
          buyerOrderColumns,
          supplierOrderColumns,

          generalLineColumns,
          buyerLineColumns,
          confirmedLineColumns,
          supplierLineColumns,
        });
      }),
    );
  });

  saveExpandValue$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(cachingExpandValue),
        withLatestFrom(
          this.store$.pipe(select(selectAuthUserId)).pipe(
            filter(v => {
              return !!v;
            }),
          ),
        ),
        tap(([{ path, value }, userId]) => {
          this.storage.set(`${userId}-${CachedKey.EXPAND_COLLAPSE}-${path}`, value);
        }),
      );
    },
    { dispatch: false },
  );
  saveOrderPanels$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateOrderPanels),
      map(({ value }) => {
        return saveToStorage({ value, key: CachedTrackedVersionKey.ORDER_PANELS });
      }),
    );
  });

  saveLinePanels$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateLinePanels),
      map(({ value }) => {
        return saveToStorage({ value, key: CachedTrackedVersionKey.LINE_PANELS });
      }),
    );
  });

  validationDestinations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(validateCachedDestinations),
      map(({ destinations }) => {
        return destinations;
      }),
      filter(list => {
        return list.length > 0;
      }),
      withLatestFrom(this.store$.pipe(select(selectAuthIdentityCompanyId))),
      concatMap(([cachedDestinationsId, id]) => {
        return this.orderSearchService.destinations$(id as string).pipe(
          map(({ data }: DestinationResponse) => {
            return data;
          }),
          map(destinations => {
            const loadedDestinationsId = destinations.map(destination => {
              return destination.id;
            });
            const availableDestinations: string[] = [];
            for (const cachedDestinationId of cachedDestinationsId) {
              if (loadedDestinationsId.includes(cachedDestinationId)) {
                availableDestinations.push(cachedDestinationId);
              }
            }

            return availableDestinations;
          }),
        );
      }),
      map((availableDestinations: string[]) => {
        return validatedCachedDestinations({ destinations: availableDestinations });
      }),
    );
  });

  saveTaskLimit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cachingTaskLimit),
      map(({ limit }) => {
        return saveToStorage({ value: limit, key: CachedKey.WORKFLOW_LIMIT });
      }),
    );
  });

  saveOrderListLimit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cachingOrderListLimit),
      map(({ limit }) => {
        return saveToStorage({ value: limit, key: CachedKey.ORDER_LIST_LIMIT });
      }),
    );
  });

  saveActivityValue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cachingActivityValue),
      map(({ path, value }) => {
        return saveToStorage({ value, key: `${CachedKey.ACTIVITY}-${path}` });
      }),
    );
  });

  saveOrdersViewMode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderListUpdateViewMode),
      map(({ viewMode }) => {
        return saveToStorage({ value: viewMode, key: CachedKey.VIEW_MODE });
      }),
    );
  });

  saveOrdersSort$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderListUpdateSort),
      map(({ sort }) => {
        return saveToStorage({ value: sort, key: CachedKey.SORT });
      }),
    );
  });

  saveOrdersFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderListUpdateFilters),
      map(({ filters }) => {
        return saveToStorage({ value: filters, key: CachedTrackedVersionKey.ORDERS_FILTERS });
      }),
    );
  });

  deleteOrdersFilters$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(orderListReset),
        withLatestFrom(
          this.store$.pipe(select(selectAuthUserId)).pipe(
            filter(v => {
              return !!v;
            }),
          ),
        ),
        tap(([_, userId]) => {
          this.storage.remove(`${userId}-${CachedTrackedVersionKey.ORDERS_FILTERS}`);
        }),
      );
    },
    { dispatch: false },
  );

  clearFiltersWhenActing = createEffect(() => {
    return this.actions$.pipe(
      ofType(clearCachedFilters),
      mergeMap(() => {
        return [orderListReset(), taskListResetFilters({ filters: { taskStatus: TaskStatus.Created } })];
      }),
    );
  });

  saveTaskFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(taskListUpdateFilters, taskListResetFilters),
      map(({ filters }) => {
        return saveToStorage({ value: filters, key: CachedTrackedVersionKey.TASK_FILTERS });
      }),
    );
  });

  saveShipmentColumns$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentUpdateColumns),
      map(({ columns }) => {
        return saveToStorage({ value: columns, key: CachedTrackedVersionKey.SHIPMENT_COLUMNS });
      }),
    );
  });

  saveOrderColumns = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderListUpdateOrderColumns),
      map(({ orderColumns }) => {
        return saveToStorage({ value: orderColumns, key: CachedTrackedVersionKey.ORDER_COLUMNS });
      }),
    );
  });

  saveLineColumns = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderListUpdateLineColumns),
      map(({ lineColumns }) => {
        return saveToStorage({ value: lineColumns, key: CachedTrackedVersionKey.LINE_COLUMNS });
      }),
    );
  });

  // start save converter CSV columns
  saveConverterOrderGeneral = createEffect(() => {
    return this.actions$.pipe(
      ofType(converterUpdateOrderGeneralColumns),
      map(({ columns }) => {
        return saveToStorage({ value: columns, key: CachedTrackedVersionKey.CONVERTER_ORDER_GENERAL });
      }),
    );
  });

  saveConverterOrderBuyer = createEffect(() => {
    return this.actions$.pipe(
      ofType(converterUpdateOrderBuyerColumns),
      map(({ columns }) => {
        return saveToStorage({ value: columns, key: CachedTrackedVersionKey.CONVERTER_ORDER_BUYER });
      }),
    );
  });

  saveConverterOrderSupplier = createEffect(() => {
    return this.actions$.pipe(
      ofType(converterUpdateOrderSupplierColumns),
      map(({ columns }) => {
        return saveToStorage({ value: columns, key: CachedTrackedVersionKey.CONVERTER_ORDER_SUPPLIER });
      }),
    );
  });

  saveConverterLineGeneral = createEffect(() => {
    return this.actions$.pipe(
      ofType(converterUpdateLineGeneralColumns),
      map(({ columns }) => {
        return saveToStorage({ value: columns, key: CachedTrackedVersionKey.CONVERTER_ORDER_LINE_GENERAL });
      }),
    );
  });

  saveConverterLineBuyer = createEffect(() => {
    return this.actions$.pipe(
      ofType(converterUpdateLineBuyerColumns),
      map(({ columns }) => {
        return saveToStorage({ value: columns, key: CachedTrackedVersionKey.CONVERTER_ORDER_LINE_BUYER });
      }),
    );
  });

  saveConverterLineSupplier = createEffect(() => {
    return this.actions$.pipe(
      ofType(converterUpdateLineSupplierColumns),
      map(({ columns }) => {
        return saveToStorage({ value: columns, key: CachedTrackedVersionKey.CONVERTER_ORDER_LINE_SUPPLIER });
      }),
    );
  });

  saveConverterLineConfirmed = createEffect(() => {
    return this.actions$.pipe(
      ofType(converterUpdateLineConfirmedColumns),
      map(({ columns }) => {
        return saveToStorage({ value: columns, key: CachedTrackedVersionKey.CONVERTER_ORDER_LINE_CONFIRMED });
      }),
    );
  });
  // end

  updateOrderRoute$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        filter(({ payload }: { payload: RouterNavigationPayload }) => {
          return payload.routerState.url === CachedURL.ORDER_LIST;
        }),
        withLatestFrom(
          this.store$.pipe(select(selectCachedOrdersViewMode)),
          this.store$.pipe(select(selectCachedOrdersSort)),
          this.store$.pipe(select(selectCachedOrderListFilters)),
          this.store$.pipe(select(selectCachedOrderListLimit)),
        ),
        map(([action, ...params]) => {
          return params.filter(v => {
            return v != null;
          });
        }),
        filter(params => {
          return params.length > 0;
        }),
        map(listParams => {
          return listParams.reduce((acc, v) => {
            return { ...acc, ...v };
          }, {});
        }),
        map(value => {
          return this.managerService.validateFromCacheFilters(
            value as OrderFilterParameters & OrderSortParameters & { viewMode?: OrderQueryViewMode },
          );
        }),
        map(async queryParams => {
          return this.router.navigate([CachedURL.ORDER_LIST], { queryParams });
        }),
      );
    },
    { dispatch: false },
  );

  updateTaskRoute$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        filter(({ payload }: { payload: RouterNavigationPayload }) => {
          return payload.routerState.url === CachedURL.WORKFLOW;
        }),
        withLatestFrom(
          this.store$.pipe(select(selectCachedTaskPageFilters)),
          this.store$.pipe(select(selectCachedTaskPageLimit)),
        ),
        map(([action, ...params]) => {
          return params.filter(v => {
            return v != null;
          });
        }),
        filter(params => {
          return params.length > 0;
        }),
        map(listParams => {
          return listParams.reduce((acc, v) => {
            return { ...acc, ...v };
          }, {});
        }),
        map(async queryParams => {
          return this.router.navigate([CachedURL.WORKFLOW], { queryParams });
        }),
      );
    },
    { dispatch: false },
  );

  updateShipmentRoute$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        filter(({ payload }: { payload: RouterNavigationPayload }) => {
          return payload.routerState.url === CachedURL.SHIPMENTS;
        }),
        withLatestFrom(this.store$.pipe(select(selectCachedShipmentFiltersQuery))),
        map(([_, params]) => {
          return params;
        }),
        filter(params => {
          return !!params;
        }),
        filter(params => {
          return (
            // eslint-disable-next-line @typescript-eslint/ban-types
            Object.values(params as {}).filter(v => {
              return v !== undefined;
            }).length > 0
          );
        }),
        map(async queryParams => {
          return this.router.navigate([CachedURL.SHIPMENTS], { queryParams });
        }),
      );
    },
    { dispatch: false },
  );

  saveToStorage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(saveToStorage),
        withLatestFrom(
          this.store$.pipe(select(selectAuthUserId)).pipe(
            filter(v => {
              return !!v;
            }),
          ),
        ),
        tap(([{ value, key }, userId]) => {
          this.storage.set(`${userId}-${key}`, value);
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private storage: StorageService,
    private router: Router,
    private store$: Store<CacheState>,
    private orderSearchService: OrderSearchService,
    private managerService: CacheManagerService,
  ) {}
}
