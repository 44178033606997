import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { PrivateGuard } from './auth/core/guards/private.guard';
import { EnvSwitchGuard } from '@app/auth/core/guards/env-switch.guard';
import { UserRole } from './user/models/enums/user-role.enum';
import { RoleGuard } from './auth/core/guards/role.guard';
import { PortLocationResolver } from './shipment/core/services/port-location.resolver';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: async () => ((await import('./auth/routes/auth-routes.module')).AuthRoutesModule),
  },
  {
    path: 'msal-callback',
    loadChildren: async () => ((await import('./msal/msal.module')).MsalModule),
  },
  {
    path: '',
    loadChildren: async () => ((await import('./dashboard/dashboard.module')).DashboardModule),
    pathMatch: 'full',
    canLoad: [PrivateGuard],
  },
  {
    path: 'analytics',
    loadChildren: async () => ((await import('./analytics/analytics.module')).AnalyticsModule),
    canLoad: [PrivateGuard],
  },
  {
    path: 'catalog',
    loadChildren: async () => (((await import('./catalog/catalog.module')).CatalogModule)),
    canLoad: [PrivateGuard, EnvSwitchGuard],
  },
  {
    path: 'company',
    loadChildren: async () => (((await import('./company/routes/company-routes.module')).CompanyRoutesModule)),
    canLoad: [PrivateGuard],
  },
  {
    path: 'forecasts',
    loadChildren: async () => (((await import('./forecast/forecast.module')).ForecastModule)),
    canLoad: [PrivateGuard, EnvSwitchGuard],
  },
  {
    path: 'orders',
    loadChildren: async () => (((await import('./order/routes/order-routes.module')).OrderRoutesModule)),
    canLoad: [PrivateGuard],
  },
  {
    path: 'rfq',
    loadChildren: async () => (await import('./rfq/routes/rfq-routes.module')).RfqRoutesModule,
    canLoad: [PrivateGuard, EnvSwitchGuard],
  },
  {
    path: 'shipments',
    loadChildren: async () => (await import('./shipment/routes/shipment-routes.module')).ShipmentRoutesModule,
    canLoad: [PrivateGuard, EnvSwitchGuard],
    resolve: {
      portLocations: PortLocationResolver,
    },
  },
  {
    path: 'super-user',
    loadChildren: async () => (((await import('./super-user/routes/super-user-routes.module')).SuperUserRoutesModule)),
    canActivate: [RoleGuard],
    data: {
      allowedRoles: [UserRole.SUPER_USER, UserRole.ADMIN, UserRole.SUPPORT],
    },
  },
  {
    path: 'user',
    loadChildren: async () => (await import('./user/routes/user-routes.module')).UserRoutesModule,
    canLoad: [PrivateGuard],
  },
  {
    path: 'workflow',
    loadChildren: async () => (((await import('./task/routes/task-routes.module')).TaskRoutesModule)),
    canLoad: [PrivateGuard],
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutesModule {}
