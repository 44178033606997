import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { PortLocationSearch } from '../../models/shipment-location.model';

@Injectable({
  providedIn: 'root',
})
export class PortLocationService {
  private portLocations: PortLocationSearch[] | null = null;
  private portLocationsSubject = new BehaviorSubject<PortLocationSearch[]>([]);

  constructor(
    private readonly http: HttpClient,
  ) {}

  private fetchPortLocations(): Observable<PortLocationSearch[]> {
    return this.http.get<PortLocationSearch[]>('assets/data/air_and_sea_ports.json').pipe(
      catchError(error => {
        console.error('Error fetching port locations:', error);

        return of([]);
      })
    );
  }

  loadPortLocations(): void {
    if (this.portLocations) {
      this.portLocationsSubject.next(this.portLocations);

      return;
    }

    this.fetchPortLocations().pipe(
      map(response => {
        this.portLocations = response;
        this.portLocationsSubject.next(this.portLocations);
      }),
    ).subscribe();
  }

  getPortLocations(): Observable<PortLocationSearch[]> {
    if (this.portLocations) {
      return of(this.portLocations);
    } else {
      this.loadPortLocations();

      return this.portLocationsSubject.asObservable();
    }
  }
}
