<div>
  <span class="section" *ngIf="isSingleDelivery && singleDeliveryDateChanged">
    <strong i18n="@@order.line.reopen.delivery">Delivery</strong>
    {{ currentDeliverySchedule[0].date | date:'dd/MM/yyyy' }}
    &#8594;
    <span class="change">
      {{ reopenDeliverySchedule[0].date | date:'dd/MM/yyyy' }}
      <tc-date-difference
        [currentDate]="findMatchingCurrentLine(reopenDeliverySchedule[0])?.date"
        [proposedDate]="reopenDeliverySchedule[0].date"
      ></tc-date-difference>
    </span>
  </span>

  <span class="section" *ngIf="isSingleDelivery && singleDeliveryQuantityChanged">
    <strong i18n="@@order.line.reopen.quantity">Quantity</strong>
    {{ currentDeliverySchedule[0].quantity }} {{buyer.item.purchaseUnitOfMeasureIso | tcUnitSymbol}}
    &#8594;
    <span class="change">
      {{ reopenDeliverySchedule[0].quantity }} {{buyer.item.purchaseUnitOfMeasureIso | tcUnitSymbol}}&nbsp;
      <tc-quantity-difference
        [currentQuantity]="findMatchingCurrentLine(reopenDeliverySchedule[0])?.quantity"
        [proposedQuantity]="reopenDeliverySchedule[0].quantity"
      ></tc-quantity-difference>
    </span>
  </span>

  <span class="section" *ngIf="!isSingleDelivery && deliveryScheduleChanged">
    <div class="schedule">
      <div class="schedule__current">
        <strong i18n="@@order.line.reopen.schedule">Current schedule</strong><br>
        <div *ngFor="let line of currentDeliverySchedule; let i = index;"
          class="tc-request__line"
          [class.highlight-missing]="currentDeliverySchedule.length > reopenDeliverySchedule.length && isUnmatchedCurrentLine(line, i)"
        >
          <span>{{ line.position }}</span>
          <span>{{ line.date | date:'dd/MM/yyyy' }}</span>
          <span>{{ line.quantity }} {{buyer.item.purchaseUnitOfMeasureIso | tcUnitSymbol}}</span>
        </div>
      </div>
      <div class="schedule__proposal">
        <strong i18n="@@order.line.reopen.request">Request</strong><br>
        <div *ngFor="let line of reopenDeliverySchedule; let i = index;" class="tc-request__line" [class.highlight-missing]="hasQuantityDiff(line, i) && hasDateDiff(line, i)">
          <div>
            <span>{{ line.position }}</span>
          </div>
          <div>
            <span [class.highlight-change]="hasDateDiff(line, i)">
              {{ line.date | date:'dd/MM/yyyy' }}
              <tc-date-difference
                *ngIf="hasDateDiff(line, i)"
                [currentDate]="findMatchingCurrentLine(line, i)?.date"
                [proposedDate]="line.date"
              ></tc-date-difference>
            </span>
          </div>
          <div>
            <span [class.highlight-change]="hasQuantityDiff(line, i)">
              {{ line.quantity }} {{buyer.item.purchaseUnitOfMeasureIso | tcUnitSymbol}}
              <tc-quantity-difference
                *ngIf="hasQuantityDiff(line, i)"
                [currentQuantity]="findMatchingCurrentLine(line, i)?.quantity"
                [proposedQuantity]="line.quantity"
              ></tc-quantity-difference>
            </span>
          </div>
        </div>
      </div>
    </div>
  </span>
</div>

<div>
  <ng-container *ngIf="reopen?.prices">
     <span class="section" *ngIf="grossPriceChanged">
    <strong i18n="@@order.line.reopen.price.gross">Gross price</strong>
       {{ current?.prices.grossPrice?.priceInTransactionCurrency | tcMoney }}
       <tc-price-unit [quantity]="current?.prices.priceUnitQuantity" [unit]="current?.prices.priceUnitOfMeasureIso"></tc-price-unit>
    &#8594;
    <span class="change">
      {{ reopen?.prices.grossPrice?.priceInTransactionCurrency | tcMoney }}
      <tc-price-unit [quantity]="reopen?.prices.priceUnitQuantity" [unit]="reopen?.prices.priceUnitOfMeasureIso"></tc-price-unit>&nbsp;
    <tc-price-difference
      [currentPrice]="current?.prices.grossPrice"
      [proposedPrice]="reopen?.prices.grossPrice"
    ></tc-price-difference>
    </span>
  </span>

    <span class="section" *ngIf="discountPercentageChanged">
    <strong i18n="@@order.line.reopen.discount">Discount</strong>
      {{ current?.prices.discountPercentage | number: '1.0-5' }}%
    &#8594;
    <span class="change">
      {{ reopen?.prices.discountPercentage | number: '1.0-5' }}%&nbsp;
    <tc-quantity-difference
      [currentQuantity]="current?.prices.discountPercentage"
      [proposedQuantity]="reopen?.prices.discountPercentage"
    ></tc-quantity-difference>
    </span>
  </span>

    <span class="section" *ngIf="netPriceChanged">
    <strong i18n="@@order.line.reopen.price.net">Net price</strong>
      {{ current?.prices.netPrice?.priceInTransactionCurrency | tcMoney }}
      <tc-price-unit [quantity]="current?.prices.priceUnitQuantity" [unit]="current?.prices.priceUnitOfMeasureIso"></tc-price-unit>
    &#8594;
    <span class="change">
      {{ reopen?.prices.netPrice?.priceInTransactionCurrency | tcMoney }}
      <tc-price-unit [quantity]="reopen?.prices.priceUnitQuantity" [unit]="reopen?.prices.priceUnitOfMeasureIso"></tc-price-unit>&nbsp;
    <tc-price-difference
      [currentPrice]="current?.prices.netPrice"
      [proposedPrice]="reopen?.prices.netPrice"
    ></tc-price-difference>
    </span>
  </span>

  </ng-container>
</div>
<ng-container *ngIf="chargeChanged">
  <tc-charge-lines-difference
    [current]="current!.chargeLines"
    [requested]="reopen!.chargeLines"
  >
  </tc-charge-lines-difference>
</ng-container>
