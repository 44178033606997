import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ShipmentLocation } from '@app/shipment/models';

@Component({
  selector: 'tc-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationComponent {
  @Input()
  location?: ShipmentLocation;

  @Input()
  mapLocation?: boolean = false;
}
