import { Pipe, PipeTransform, inject } from '@angular/core';
import { PortLocationService } from '@app/shipment/core/services/port-location.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'tcLocationName',
})
export class LocationNamePipe implements PipeTransform {
  private portLocationService = inject(PortLocationService);

  transform(unlocode: string | null): Observable<string | null> {
    if (!unlocode) {
      return new Observable(observer => {
        observer.next(null);
        observer.complete();
      });
    }

    return this.portLocationService.getPortLocations().pipe(
      map(portLocations => {
        const location = portLocations.find(port => port.unlocode === unlocode);

        return location ? `${location.name}, ${location.country}` : unlocode;
      }),
    );
  }
}
