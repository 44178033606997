import { createAction, props } from '@ngrx/store';
import { EntityTypeOptionValue } from '@app/activity/models/activity-entity-type-option.model';
import { CachedActivityType, CachedExpandType } from '@app/cache/state/cache.reducer';
import { OrderQueryFilters, OrderQuerySort, OrderQueryViewMode } from '@app/order/models';
import { TaskQueryFilters } from '@app/task/models';
import { OrderedColumn } from '@app/shared/components/table-columns/table-columns.component';
import { ConvertedColumn } from '@app/converter/models';
import { CachedKey, CachedTrackedVersionKey } from '@app/cache/models';
import { ShipmentQueryFilters } from '@app/shipment/models';
import { OrderPanelType } from '@app/order/private/components/order-panels/order-panels.component';
import { LinePanelType } from '@app/order/routes/order-line-detail/containers/view/line-panels/line-panels.component';

export enum CacheActionType {
  START_INIT_CACHED = '[Cache] start init after versions validation',

  GET_CACHED_VALUE = '[Cache] get cached activity value',
  CACHING_ACTIVITY_VALUE = '[Cache] caching activity value',

  GET_CACHED_ORDER_PANELS_CONFIG = '[Cache] get from storage cached order panels config',
  CACHING_ORDER_PANELS_CONFIG = '[Cache] caching order panels config',

  GET_CACHED_LINE_PANELS_CONFIG = '[Cache] get from storage cached LINE panels config',
  CACHING_LINE_PANELS_CONFIG = '[Cache] caching LINE panels config',

  INIT_CACHED_EXPAND_VALUES = '[Cache] init cached expand values',
  GET_CACHED_EXPAND_VALUE = '[Cache] get cached expand value',
  CACHING_EXPAND_VALUE = '[Cache] caching expand value',

  CACHING_TASK_LIMIT = '[Cache] caching task limit',
  CACHING_ORDER_LIST_LIMIT = '[Cache] caching order list limit',
  GET_CACHED_TASK_LIMIT = '[Cache] get cached task limit',
  GET_CACHED_ORDER_LIST_LIMIT = '[Cache] get cached order list limit',

  GET_CACHED_VIEW_MODE = '[Cache] get cached orders view mode',
  GET_CACHED_ORDERS_SORT = '[Cache] get cached orders sort',
  GET_CACHED_ORDERS_FILTERS = '[Cache] get cached orders filters',
  GET_CACHED_TASK_FILTERS = '[Cache] get cached task filters',
  GET_CACHED_ORDERS_COLUMNS = '[Cache] get cached orders columns',
  GET_CACHED_SHIPMENT_COLUMNS = '[Cache] get cached shipment columns',

  GET_CACHED_SHIPMENT_QUERY = '[Cache] get cached shipment query',

  SET_CACHED_VALUE = '[Cache] save value to storage by key',

  INIT_CACHED_CONVERTER_VALUES = '[Cache] init cached converter values',
  GET_CACHED_CONVERTER_COLUMNS = '[Cache] get cached converter columns',

  VALIDATE_CACHED_DESTINATIONS = '[Cache] validate cached destinations',
  VALIDATED_CACHED_DESTINATIONS = '[Cache] validated cached destinations',

  CLEAR_CACHED_FILTERS_FOR_ACTING = '[Cache] clear cached filters for acting',
}

export const startInitCached = createAction(CacheActionType.START_INIT_CACHED, props<{ userId: string }>());

export const saveToStorage = createAction(
  CacheActionType.SET_CACHED_VALUE,
  props<{ value: any; key: CachedTrackedVersionKey | CachedKey | string }>(),
);

export const initCachedExpandValues = createAction(
  CacheActionType.INIT_CACHED_EXPAND_VALUES,
  props<{ userId: string }>(),
);

export const getCachedExpandValue = createAction(
  CacheActionType.GET_CACHED_EXPAND_VALUE,
  props<{ path: CachedExpandType | OrderPanelType | LinePanelType; value: boolean | null }>(),
);

export const cachingExpandValue = createAction(
  CacheActionType.CACHING_EXPAND_VALUE,
  props<{ path: CachedExpandType | OrderPanelType | LinePanelType; value: boolean }>(),
);

export const getCachedActivityValue = createAction(
  CacheActionType.GET_CACHED_VALUE,
  props<{ path: CachedActivityType; value: string | null }>(),
);

export const cachingActivityValue = createAction(
  CacheActionType.CACHING_ACTIVITY_VALUE,
  props<{ path: CachedActivityType; value: EntityTypeOptionValue }>(),
);

export const getCachedViewMode = createAction(
  CacheActionType.GET_CACHED_VIEW_MODE,
  props<{ path: string; viewMode: OrderQueryViewMode | null }>(),
);

export const getCachedOrdersSort = createAction(
  CacheActionType.GET_CACHED_ORDERS_SORT,
  props<{ path: string; sort: OrderQuerySort | null }>(),
);

export const getCachedOrdersFilters = createAction(
  CacheActionType.GET_CACHED_ORDERS_FILTERS,
  props<{ filters: OrderQueryFilters | null }>(),
);

export const getCachedTaskLimit = createAction(
  CacheActionType.GET_CACHED_TASK_LIMIT,
  props<{ offset: number; limit: number }>(),
);

export const getCachedOrderListLimit = createAction(
  CacheActionType.GET_CACHED_ORDER_LIST_LIMIT,
  props<{ offset: number; limit: number }>(),
);

export const getCachedTaskFilters = createAction(
  CacheActionType.GET_CACHED_TASK_FILTERS,
  props<{ filters: TaskQueryFilters | null }>(),
);

export const cachingTaskLimit = createAction(
  CacheActionType.CACHING_TASK_LIMIT,
  props<{ offset: number; limit: number }>(),
);

export const cachingOrderListLimit = createAction(
  CacheActionType.CACHING_ORDER_LIST_LIMIT,
  props<{ offset: number; limit: number }>(),
);

export const getCachedOrderListColumns = createAction(
  CacheActionType.GET_CACHED_ORDERS_COLUMNS,
  props<{
    orderColumns: OrderedColumn[] | null;
    lineColumns: OrderedColumn[] | null;
  }>(),
);

export const getCachedShipmentColumns = createAction(
  CacheActionType.GET_CACHED_SHIPMENT_COLUMNS,
  props<{ columns: OrderedColumn[] | null; }>(),
);

export const initCachedConverterValues = createAction(
  CacheActionType.INIT_CACHED_CONVERTER_VALUES,
  props<{ userId: string }>(),
);

export const getCachedConverterColumns = createAction(
  CacheActionType.GET_CACHED_CONVERTER_COLUMNS,
  props<{
    generalOrderColumns: ConvertedColumn[];
    buyerOrderColumns: ConvertedColumn[];
    supplierOrderColumns: ConvertedColumn[];

    generalLineColumns: ConvertedColumn[];
    buyerLineColumns: ConvertedColumn[];
    confirmedLineColumns: ConvertedColumn[];
    supplierLineColumns: ConvertedColumn[];
  }>(),
);

export const getCachedShipmentQuery = createAction(
  CacheActionType.GET_CACHED_SHIPMENT_QUERY,
  props<{ filters: ShipmentQueryFilters | null }>(),
);

export const validateCachedDestinations = createAction(
  CacheActionType.VALIDATE_CACHED_DESTINATIONS,
  props<{ destinations: string[] }>(),
);

export const validatedCachedDestinations = createAction(
  CacheActionType.VALIDATED_CACHED_DESTINATIONS,
  props<{ destinations: string[] }>(),
);

export const clearCachedFilters = createAction(CacheActionType.CLEAR_CACHED_FILTERS_FOR_ACTING);

export const updateOrderPanels = createAction(
  CacheActionType.CACHING_ORDER_PANELS_CONFIG,
  props<{ value: Record<OrderPanelType, number> }>(),
);

export const getCachedOrderPanels = createAction(
  CacheActionType.GET_CACHED_ORDER_PANELS_CONFIG,
  props<{ value: Record<OrderPanelType, number> | null }>(),
);

export const updateLinePanels = createAction(
  CacheActionType.CACHING_LINE_PANELS_CONFIG,
  props<{ value: Record<LinePanelType, number> }>(),
);

export const getCachedLinePanels = createAction(
  CacheActionType.GET_CACHED_LINE_PANELS_CONFIG,
  props<{ value: Record<LinePanelType, number> | null }>(),
);
