import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CacheState } from '@app/cache/state/cache.reducer';
import { mapFiltersToQueryParameters, mapSortToQueryParameters } from '@app/order/routes/order-list/state/util';
import { mapShipmentFiltersToQueryParameters } from '@app/shipment/core/state/util';

export const cacheFeatureName = 'cache';

const selectCacheState = createFeatureSelector<CacheState>(cacheFeatureName);
const selectCachedActivity = createSelector(selectCacheState, ({ activity }) => {
  return activity;
});
export const selectCachedOrderPanels = createSelector(selectCacheState, ({ orderDetailPanels }) => {
  return orderDetailPanels;
});

export const selectCachedLinePanels = createSelector(selectCacheState, ({ lineDetailPanels }) => {
  return lineDetailPanels;
});

export const selectCachedOrderActivity = createSelector(selectCachedActivity, ({ order }) => {
  return order;
});
export const selectCachedOrderLineActivity = createSelector(selectCachedActivity, ({ orderLine }) => {
  return orderLine;
});
export const selectCachedDashboardActivity = createSelector(selectCachedActivity, ({ dashboard }) => {
  return dashboard;
});
export const selectCachedSettingsActivity = createSelector(selectCachedActivity, ({ settings }) => {
  return settings;
});
export const selectCachedNetworkActivity = createSelector(selectCachedActivity, ({ network }) => {
  return network;
});
export const selectCachedShipmentActivity = createSelector(selectCachedActivity, ({ shipment }) => {
  return shipment;
});

const selectCachedOrdersPage = createSelector(selectCacheState, ({ orders }) => {
  return orders;
});
export const selectCachedConverterColumns = createSelector(selectCacheState, ({ converter }) => {
  return converter;
});

export const selectCachedOrdersViewMode = createSelector(selectCachedOrdersPage, ({ viewMode }) => {
  return viewMode ? { viewMode } : null;
});
export const selectCachedOrdersSort = createSelector(selectCachedOrdersPage, ({ sort }) => {
  return sort ? mapSortToQueryParameters(sort) : null;
});
export const selectCachedOrderListFilters = createSelector(selectCachedOrdersPage, ({ filters }) => {
  return filters ? mapFiltersToQueryParameters(filters) : null;
});
export const selectCachedOrderListLimit = createSelector(selectCachedOrdersPage, ({ limit }) => {
  return limit ? { limit } : { limit: 10 };
});
export const selectCachedOrderListColumns = createSelector(selectCachedOrdersPage, ({ lineColumns, orderColumns }) => {
  return { lineColumns, orderColumns };
});

const selectCachedShipmentPage = createSelector(selectCacheState, ({ shipment }) => {
  return shipment;
});
export const selectCachedShipmentListColumns = createSelector(selectCachedShipmentPage, ({ columns }) => {
  return { columns };
});
export const selectCachedShipmentFilters = createSelector(selectCachedShipmentPage, ({ filters }) => {
  return filters ? filters : null;
});
export const selectCachedShipmentFiltersQuery = createSelector(selectCachedShipmentPage, ({ filters }) => {
  return filters && mapShipmentFiltersToQueryParameters(filters);
});
export const selectCachedShipmentQuery = createSelector(selectCachedShipmentFilters, filters => {
  return filters && { query: filters.query };
});
export const selectCachedShipmentCompanyIds = createSelector(selectCachedShipmentFilters, filters => {
  return filters && { companyIds: filters.companyIds };
});

const selectCachedTaskPage = createSelector(selectCacheState, ({ task }) => {
  return task;
});
export const selectCachedTaskPageFilters = createSelector(selectCachedTaskPage, ({ filters }) => {
  return filters ? filters : null;
});
export const selectCachedTaskPageLimit = createSelector(selectCachedTaskPage, ({ limit }) => {
  return limit ? { limit } : { limit: 10 };
});

const selectCachedExpand = createSelector(selectCacheState, ({ expand }) => {
  return expand;
});
export const selectCachedExpandProcess = createSelector(selectCachedExpand, ({ processStatus }) => {
  return processStatus;
});
export const selectCachedExpandPrediction = createSelector(selectCachedExpand, ({ prediction }) => {
  return prediction;
});
export const selectCachedExpandLogistics = createSelector(selectCachedExpand, ({ logisticsStatus }) => {
  return logisticsStatus;
});
export const selectCachedExpandCustomLabels = createSelector(selectCachedExpand, ({ customLabels }) => {
  return customLabels;
});
export const selectCachedExpandContactIds = createSelector(selectCachedExpand, ({ contactIds }) => {
  return contactIds;
});
export const selectCachedExpandDestinations = createSelector(selectCachedExpand, ({ destinations }) => {
  return destinations;
});
export const selectCachedExpandSearch = createSelector(selectCachedExpand, ({ search }) => {
  return search;
});
export const selectCachedExpandCompanyIds = createSelector(selectCachedExpand, ({ companyIds }) => {
  return companyIds;
});
export const selectCachedExpandType = createSelector(selectCachedExpand, ({ type }) => {
  return type;
});
export const selectCachedExpandContact = createSelector(selectCachedExpand, ({ contact }) => {
  return contact;
});
export const selectCachedExpandTaskModule = createSelector(selectCachedExpand, ({ module }) => {
  return module;
});
export const selectCachedExpandTaskCategory = createSelector(selectCachedExpand, ({ category }) => {
  return category;
});
export const selectCachedExpandAssignedTo = createSelector(selectCachedExpand, ({ assignedTo }) => {
  return assignedTo;
});
export const selectCachedExpandOrderType = createSelector(selectCachedExpand, ({ orderType }) => {
  return orderType;
});

export const selectCachedExpandLineTypes = createSelector(
  selectCachedExpand,
  ({
    lineSummary,
    lineDeliveryHistory,
    lineChargeInfo,
    lineContacts,
    lineCertification,
    lineDeliverySchedule,
    lineDocuments,
    lineItem,
    lineItemDetails,
    lineNotes,
    linePricing,
    lineProperties,
    lineShipping,
    lineOrderOverview,
  }) => {
    return {
      lineSummary,
      lineDeliveryHistory,
      lineChargeInfo,
      lineContacts,
      lineCertification,
      lineDeliverySchedule,
      lineDocuments,
      lineItem,
      lineItemDetails,
      lineNotes,
      linePricing,
      lineProperties,
      lineShipping,
      lineOrderOverview,
    };
  },
);

export const selectCachedExpandPanelSummary = createSelector(selectCachedExpand, ({ summary }) => {
  return summary;
});

export const selectCachedExpandPanelNotes = createSelector(selectCachedExpand, ({ notes }) => {
  return notes;
});
export const selectCachedExpandPanelProperties = createSelector(selectCachedExpand, ({ properties }) => {
  return properties;
});
export const selectCachedExpandPanelDestination = createSelector(selectCachedExpand, ({ destination }) => {
  return destination;
});

export const selectCachedExpandPanelTerms = createSelector(selectCachedExpand, ({ terms }) => {
  return terms;
});

export const selectCachedExpandPanelContacts = createSelector(selectCachedExpand, ({ contacts }) => {
  return contacts;
});

export const selectCachedExpandPanelInvoice = createSelector(selectCachedExpand, ({ invoice }) => {
  return invoice;
});

export const selectCachedExpandPanelDocuments = createSelector(selectCachedExpand, ({ documents }) => {
  return documents;
});
