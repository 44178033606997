<ng-container *ngIf="location">
  <ng-container *ngIf="mapLocation; else noMap">
    <p>{{ location?.id | tcLocationName | async }}</p>
  </ng-container>
  <ng-template #noMap>
    <p>{{location.id}} {{location.idScheme}}</p>
  </ng-template>
  <p>
    <ng-container *ngFor="let name of location.names">
      {{name}}
    </ng-container>
  </p>
  <p>
    <ng-container *ngFor="let addressLine of location.addressLines">
      {{addressLine}}
    </ng-container>
  </p>
  <p>{{location.postalCode}} {{location.city}} <span>{{location.countryCodeIso2}}</span></p>
</ng-container>
