import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PortLocationService } from './port-location.service';

@Injectable({
  providedIn: 'root',
})
export class PortLocationResolver implements Resolve<void> {
  constructor(private readonly portLocationService: PortLocationService) {}

  resolve(): Observable<void> {
    this.portLocationService.loadPortLocations();

    return of(undefined);
  }
}
