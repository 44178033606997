import { NgModule } from '@angular/core';
import { RootSharedModule } from '@app/shared/root-shared.module';
import { AftershipComponent } from './components/aftership/aftership.component';
import { MarineTrafficComponent } from './components/marine-traffic/marine-traffic.component';
import { LocationComponent } from './components/location/location.component';
import { LocationNamePipe } from './pipes/location-name.pipe';

const components = [AftershipComponent, MarineTrafficComponent, LocationComponent];
const pipes = [LocationNamePipe];

@NgModule({
  imports: [RootSharedModule],
  declarations: [components, pipes],
  exports: [components, pipes],
})
export class ShipmentSharedModule {}
